import { css } from '@emotion/react'
import React from 'react'
import { useTheme } from '@findep/microfronts-core'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import PropTypes from 'prop-types'

function ButtonDegradefirma({ textButton, onClick, icon, disabled, id, colorTheme, fontsize12 }) {
  const { palette } = useTheme()
  const [gradOne] = React.useState(colorTheme === 'warning' ? palette.warning.light : palette.primary.light)
  const [gradTwo] = React.useState(colorTheme === 'warning' ? palette.warning.main : palette.primary.main)
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    buttonColor: {
      background: `linear-gradient(${gradOne}, ${gradTwo})`,
      color: 'white'
    }
  }))

  const Icon = icon;

  const colorDisabled = css`
    color: ${palette.text.disabled};
  `


  const iconColor = css`
    ${disabled ? colorDisabled : `color: white;`}
  `
  const h1Color = css`
    ${disabled ? colorDisabled : `background: linear-gradient(${gradOne}, ${gradTwo})`}
  `
  const h1Style = css`
    color: 'white';
    ${h1Color}
  `
  const TextC = css`
   ${disabled ? colorDisabled : `color: white;`}
   ${fontsize12 ? `font-size: 12px;` : `font-size: 1rem;`}
   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
   font-weight: bold;
   line-height: 1.5;
   letter-spacing: 0.00938em;
  `

  const iconStyle = css`
    margin-right: 0.6em;
    ${iconColor}
  `
  const classes = useStyles()

  return (

    <Fab id={id} variant='extended' onClick={onClick} disabled={disabled} css={h1Style}>
      {icon ? <Icon className={classes.extendedIcon} css={iconStyle} /> : ''}
      <span css={TextC}>
        {textButton}
      </span>
    </Fab>

  )
}

ButtonDegradefirma.propTypes = {
  textButton: PropTypes.element.isRequired,
  id: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.object,
  disabled: PropTypes.bool,
  colorTheme: PropTypes.string,
  fontsize12: PropTypes.bool,
}

ButtonDegradefirma.default = {
  colorTheme: 'primary',
}

export default ButtonDegradefirma
